import * as React from 'react';
import { ScreenWidthContainerProps } from '../ScreenWidthContainer.types';
import MeshContainer from '../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';
import { ScreenWidthContainerSkinMap } from './skinMap';

const ScreenWidthContainer: React.FC<ScreenWidthContainerProps> = ({
  skin,
  id,
  meshProps,
  children,
}) => {
  const ContainerClass = ScreenWidthContainerSkinMap[skin];
  return (
    <ContainerClass wrapperProps={{ id, eventHandlers: {} }}>
      <MeshContainer id={id} {...meshProps} children={children} />
    </ContainerClass>
  );
};

export default ScreenWidthContainer;
