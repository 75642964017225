import React from 'react';
import skinsStyles from './styles/NoiseScreen.scss';
import BaseScreenSkin, { BaseScreenSkinProps } from './BaseScreenSkin';

const NoiseScreen: React.FC<Omit<
  BaseScreenSkinProps,
  'skin' | 'skinsStyle'
>> = props => <BaseScreenSkin {...props} skinsStyle={skinsStyles} />;

export default NoiseScreen;
