import * as React from 'react';
import classNames from 'classnames';
import { SkinScreenWidthContainerProps } from '../../../SkinScreenWidthContainer';
import skinsStyle from './styles/skins.scss';

const ThreeDeeScreen: React.FC<SkinScreenWidthContainerProps> = ({
  wrapperProps: { id, tagName = 'div', tabIndex, eventHandlers },
  children,
  animation,
}) => {
  const SemanticElement = tagName as keyof JSX.IntrinsicElements;

  return (
    <SemanticElement
      id={id}
      className={classNames(
        skinsStyle.ThreeDeeScreen,
        animation && skinsStyle[animation],
      )}
      {...(tabIndex !== undefined ? { tabIndex } : {})}
      {...eventHandlers}
    >
      <div className={skinsStyle.screenWidthBackground}></div>
      <div className={skinsStyle.centeredContent}>
        <div className={skinsStyle.centeredContentBg}>
          <div className={skinsStyle.bg}>
            <div className={skinsStyle.border}></div>
          </div>
        </div>
        <div className={skinsStyle.inlineContent}>{children}</div>
      </div>
    </SemanticElement>
  );
};

export default ThreeDeeScreen;
