import React from 'react';
import { ThunderboltTypes } from '@wix/editor-elements-types';
import { SkinScreenWidthContainerProps } from '../SkinScreenWidthContainer';
import {
  DefaultScreen,
  BevelScreen,
  InnerShadowScreen,
  ThreeDeeScreen,
  LiftedTopScreen,
  LiftedBottomScreen,
  ShadowBottomScreen,
  IronScreen,
  DoubleBorderScreen,
  SolidScreen,
  BoxScreen,
  TransparentScreen,
  NoiseScreen,
  BlankScreen,
  LineBottomScreen,
  WoodScreen,
  AfterScroll,
} from './skinComps';

export type ScreenWidthContainerSkin =
  | 'DefaultScreen'
  | 'BevelScreen'
  | 'InnerShadowScreen'
  | 'ThreeDeeScreen'
  | 'LiftedTopScreen'
  | 'LiftedBottomScreen'
  | 'ShadowBottomScreen'
  | 'IronScreen'
  | 'DoubleBorderScreen'
  | 'TransparentScreen'
  | 'AfterScroll'

  // Deprecated Skins
  | 'BlankScreen'
  | 'BoxScreen'
  | 'SolidScreen'
  | 'NoiseScreen'
  | 'LineBottomScreen'
  | 'WoodScreen';

export const SKIN_NAMES: ThunderboltTypes.Identity<ScreenWidthContainerSkin> = {
  DefaultScreen: 'DefaultScreen',
  BlankScreen: 'BlankScreen',
  BevelScreen: 'BevelScreen',
  InnerShadowScreen: 'InnerShadowScreen',
  ThreeDeeScreen: 'ThreeDeeScreen',
  TransparentScreen: 'TransparentScreen',
  LiftedTopScreen: 'LiftedTopScreen',
  LiftedBottomScreen: 'LiftedBottomScreen',
  IronScreen: 'IronScreen',
  DoubleBorderScreen: 'DoubleBorderScreen',
  BoxScreen: 'BoxScreen',
  SolidScreen: 'SolidScreen',
  NoiseScreen: 'NoiseScreen',
  WoodScreen: 'WoodScreen',
  LineBottomScreen: 'LineBottomScreen',
  ShadowBottomScreen: 'ShadowBottomScreen',
  AfterScroll: 'AfterScroll',
} as const;

export const ScreenWidthContainerSkinMap: {
  [P in keyof typeof SKIN_NAMES]: React.FC<SkinScreenWidthContainerProps>;
} = {
  DefaultScreen,
  BlankScreen,
  BevelScreen,
  InnerShadowScreen,
  ThreeDeeScreen,
  TransparentScreen,
  LiftedTopScreen,
  LiftedBottomScreen,
  IronScreen,
  DoubleBorderScreen,
  BoxScreen,
  SolidScreen,
  NoiseScreen,
  WoodScreen,
  ShadowBottomScreen,
  LineBottomScreen,
  AfterScroll,
};
