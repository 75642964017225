import React from 'react';
import skinsStyles from './styles/LineBottomScreen.scss';
import BaseScreenSkin, { BaseScreenSkinProps } from './BaseScreenSkin';

const LineBottomScreen: React.FC<Omit<
  BaseScreenSkinProps,
  'skin' | 'skinsStyle'
>> = props => <BaseScreenSkin {...props} skinsStyle={skinsStyles} />;

export default LineBottomScreen;
