import React from 'react';
import skinsStyles from './styles/WoodScreen.scss';
import BaseScreenSkin, { BaseScreenSkinProps } from './BaseScreenSkin';

const WoodScreen: React.FC<Omit<
  BaseScreenSkinProps,
  'skin' | 'skinsStyle'
>> = props => <BaseScreenSkin {...props} skinsStyle={skinsStyles} />;

export default WoodScreen;
